import { Component, EventEmitter, Input, Output } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Form, FormBuilder, FormGroup } from '@angular/forms';
import { ConvertService } from 'src/app/services/convert.service';
import { settingCompany } from './settig.interface';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-settig-company',
  templateUrl: './settig-company.component.html',
  styleUrls: ['./settig-company.component.scss']
})
export class SettigCompanyComponent {
  modelItem?: settingCompany
  @Input('model') set item(v: any) {
    if (!v) return
    this.modelItem = v;
    this.viewImage = v.Organization_logo
    this.form.patchValue(v)

  }

  @Output('updateItem') updateItem = new EventEmitter()

  constructor(private http: HttpClient,
    private fb: FormBuilder,
    private convert: ConvertService,
    private alert: AlertService) {
    this.formControlName();
  }

  form: FormGroup | any

  formControlName() {
    this.form = this.fb.group({
      Organization_name: '',
      Organization_logo: ''
    })
  }

  viewImage: string = ""
  uploadFile(e: any) {
    this.viewImage = e.base64
    this.form.get('Organization_logo').setValue(e.file)
  }

  create() {
    let model = this.convert.paseFormData(this.form.value)
    this.http.post(`organizations/create`, model).subscribe({
      next: (res) => {
        this.updateItem.emit(res)
        this.alert.notify('Create Organization Successfull', 'success')
      }, error: (err) => {
        this.alert.notify(err.error.message, 'warning')
      }
    })
  }

  update() {
    let model = this.convert.paseFormData(this.form.value)
    this.http.put(`organizations/update/${this.modelItem?.Organization_id}`, model).subscribe({
      next: (res) => {
        this.updateItem.emit(res)
        this.alert.notify('Update Organization Successfull', 'success')
      }, error: (err) => {
        this.alert.notify(err.error.message, 'warning')
      }
    })
  }


}
